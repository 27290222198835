import { lazy, Suspense, useEffect, useState } from 'react';
import useGTM from './hooks/useGTM';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { BreadcrumbProvider } from './context/BreadcrumbContext';
import { FrequentlyVisitedProvider } from './context/FrequentlyVisitedContext';
import { UserManagementProvider } from './context/UserManagementContext';
import { SearchProvider } from './context/SearchContext';
import { useAuth, hasAuthParams } from "react-oidc-context";
import {getUser, KeycloakProvider} from './context/KeycloakContext';
import { ContactsProvider } from './context/ContactsContext';
import useInactivityLogout from './hooks/useInactivityLogout';
import { usePageVisibility } from './hooks/usePageVisibility';
import Spinner from './components/Spinner/Spinner';

// Import components
import Breadcrumb from './layout/breadcrumb/Breadcrumb';
import Footer from './layout/footer/Footer';
import BackToTop from './components/BackToTop/BackToTop';
import SkeletonLayout from './layout/skeleton/SkeletonLayout';
import SkeletonHeader from './layout/skeleton/SkeletonHeader';
import Loading from './components/Loading/Loading';
import {AccessTokenCallback, SilentRenewErrorCallback} from "oidc-client-ts";

// Import lazy-loaded pages
const Header = lazy(() => import('./layout/header/Header'));
const Dashboard = lazy(() => import('./layout/dashboard/Dashboard'));
const NotificationsPage = lazy(() => import('./pages/Notifications/NotificationsPage'));
const ContactPage = lazy(() => import('./pages/Contact/ContactPage'));
const ContentHub = lazy(() => import('./pages/ContentHub/ContentHub'));
const DocumentsPage = lazy(() => import('./pages/FinancialDocuments/DocumentsPage'));
const Contracts = lazy(() => import('./pages/FinancialDocuments/Contracts'));
const SearchResults = lazy(() => import('./pages/Search/SearchResults'));
const AccountDetails = lazy(() => import('./pages/UserManagement/AccountDetailsAndUsers/AccountDetails'));
const ProfilePage = lazy(() => import('./pages/UserManagement/Profile/ProfilePage'));
const Application = lazy(() => import('./pages/Application/Application'));
const ManageAccessRightsPage = lazy(() => import('./pages/UserManagement/ManageAccessRights/ManageAccessRightsPage'));
const NotFoundPage = lazy(() => import('./pages/NotFound/NotFound'));

function App() {
  // Includes GTM
  useGTM();
  const auth = useAuth();
  const isVisible = usePageVisibility()
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  useInactivityLogout();

  useEffect(() => {
    if (isVisible) {
        const user = getUser()
        console.log('Token expired:',user?.expired, ',expired in:', user?.expires_in)
        // token expired
        if (user?.expired && auth.isAuthenticated) {
            console.log('token is expired after comming back, renewing')
            const currentLocation = window.location;
            auth.signinRedirect({ redirectMethod: 'assign', redirect_uri: (process.env.REACT_APP_OIDC_REDIRECT + (currentLocation.pathname === '/' ? '' : currentLocation.pathname)).replace(/([^:]\/)\/+/g, "$1"), redirectTarget: 'self' })
        }
        //resume silent token renew when tab is visible again
        auth.startSilentRenew()
    } else {
        //stop silent token renew when tab is not visible
        auth.stopSilentRenew()
    }
  }, [isVisible])

    useEffect(() => {
        const handleRenewError : SilentRenewErrorCallback = (e) => {
            console.log('Token renewing error, signin with redirect', e)
            const currentLocation = window.location;
            auth.signinRedirect({ redirectMethod: 'assign', redirect_uri: (process.env.REACT_APP_OIDC_REDIRECT + (currentLocation.pathname === '/' ? '' : currentLocation.pathname)).replace(/([^:]\/)\/+/g, "$1"), redirectTarget: 'self' })
        }

        const handleTokenExpired: AccessTokenCallback = () => {
            console.log('token expired')
            //auth.signinRedirect({ redirectMethod: 'replace', redirect_uri: (process.env.REACT_APP_OIDC_REDIRECT + (currentLocation.pathname === '/' ? '' : currentLocation.pathname)).replace(/([^:]\/)\/+/g, "$1"), redirectTarget: 'self' })
        }

        auth.events.addSilentRenewError(handleRenewError)
        auth.events.addAccessTokenExpired(handleTokenExpired)

        return () => {
            auth.events.removeSilentRenewError(handleRenewError)
            auth.events.removeSilentRenewError(handleTokenExpired)
        }
    }, []);

  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  useEffect(() => {
    const handleSignInCallback = async () => {
    if (/[?&]state=/.test(window.location.search)) {
        // Remove the payload from the URL
        window.history.replaceState({}, document.title, window.location.pathname);

        setHasTriedSignin(true);
      }
    };
    if (auth.isAuthenticated) {
      handleSignInCallback();
    }
  }, [auth]);

  if (auth.isLoading) {
    return <div className='loading-page'><Spinner /></div>
  }

  if (auth.isAuthenticated) {
    return (
      <KeycloakProvider>
        <Router>
          <ContactsProvider>
            <FrequentlyVisitedProvider>
              <BreadcrumbProvider>
                <SearchProvider>
                  <Suspense fallback={<SkeletonHeader />}>
                    <Header />
                  </Suspense>
                  <Breadcrumb />
                  <UserManagementProvider>
                    <Routes>
                      <Route path='/' element={
                        <Suspense fallback={<SkeletonLayout />}>
                          <Dashboard />
                        </Suspense>
                      } />
                      <Route path={process.env.REACT_APP_NOTIFICATIONS_PAGE} element={
                        <Suspense fallback={<Loading />}>
                          <NotificationsPage />
                        </Suspense>
                      } />
                      <Route path={process.env.REACT_APP_CONTACT_PAGE} element={
                        <Suspense fallback={<Loading />}>
                          <ContactPage />
                        </Suspense>
                      } />
                      <Route path='/content-hub/:section' element={
                        <Suspense fallback={<Loading />}>
                          <ContentHub />
                        </Suspense>
                      } />
                      <Route path={process.env.REACT_APP_FINANCIAL_DOCUMENTS_PAGE} element={
                        <Suspense fallback={<Loading />}>
                          <DocumentsPage pageType='financial' />
                        </Suspense>
                      } />
                      <Route path={process.env.REACT_APP_TECHNICAL_DOCUMENTS_PAGE} element={
                        <Suspense fallback={<Loading />}>
                          <DocumentsPage pageType='technical' />
                        </Suspense>
                      } />
                      <Route path={process.env.REACT_APP_CONTRACTS_PAGE} element={
                        <Suspense fallback={<Loading />}>
                          <Contracts />
                        </Suspense>
                      } />
                      <Route path={process.env.REACT_APP_SEARCH_PAGE} element={
                        <Suspense fallback={<Loading />}>
                          <SearchResults />
                        </Suspense>
                      } />
                      <Route path={process.env.REACT_APP_PROFILE_PAGE} element={
                        <Suspense fallback={<Loading />}>
                          <ProfilePage />
                        </Suspense>
                      } />
                      <Route path='/application/:app' element={
                        <Suspense fallback={<Loading />}>
                          <Application />
                        </Suspense>
                      } />
                      <Route path={process.env.REACT_APP_ACCOUNT_DETAILS_PAGE} element={
                        <Suspense fallback={<Loading />}>
                          <AccountDetails />
                        </Suspense>
                      } />
                      <Route path={process.env.REACT_APP_ACCESS_RIGHTS_PAGE} element={
                        <Suspense fallback={<Loading />}>
                          <ManageAccessRightsPage />
                        </Suspense>
                      } />
                      <Route path="*" element={
                        <Suspense fallback={<Loading />}>
                          <NotFoundPage />
                        </Suspense>
                      } />
                    </Routes>
                  </UserManagementProvider>
                </SearchProvider>
              </BreadcrumbProvider>
              <Footer />
              <BackToTop />
            </FrequentlyVisitedProvider>
          </ContactsProvider>
        </Router>
      </KeycloakProvider>
    );
  }
  return null;
}


export default App;
