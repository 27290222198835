import { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useMemo } from 'react';
import { useAuth } from 'react-oidc-context';
import { jwtDecode } from "jwt-decode";
import { User } from 'oidc-client-ts';
import axios, { AxiosResponse } from 'axios';

type DataAccess = Record<string, { ids: number[] }>;

type RessourceAccess = Record<string, {
  roles: string[];
}>;

type JwtPayload = {
  exp: number;
  iat: number;
  auth_time: number;
  jti: string;
  iss: string;
  aud: string;
  sub: string;
  typ: string;
  azp: string;
  session_state: string;
  at_hash: string;
  sid: string;
  'iv-user': string;
  internal?: boolean;
  email_verified: boolean;
  last_name: string;
  preferred_username: string;
  data_access: Record<string, DataAccess>;
  resource_access: RessourceAccess;
  first_name: string;
  email: string;
  account: {
    id: number;
  };
}

export type UserAccount = {
  id: string,
  name: string,
  commercialName: string,
  shortCode: string,
  countryISO3: string,
  countryName: string,
  apps: string[],
  isMainAccount: boolean
}

type KeycloakContextType = {
  activeToken?: JwtPayload | null;
  accessToken?: string | null;
  accountId: number | null;
  personalUserId: string | null;
  userAccounts: UserAccount[];
  applicationsRoles: RessourceAccess | null;
  isEasyConnect: boolean;
  userAccountsLoading: boolean;
  userAccountsError: boolean;
  impersonateisLoading: boolean;
  setAccountId: (value: number | null) => void;
  handleLogout: () => void;
  handleLogoutSilent: () => void;
  handleLoginSilent: () => void;
}

const KeycloakContext = createContext<KeycloakContextType | undefined>(undefined);

export function getUser() {
  const oidcStorage = sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_TOKEN}`)
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

export function KeycloakProvider({ children }: { children: ReactNode }) {
  const [activeToken, setActiveToken] = useState<JwtPayload | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [accountId, setAccountId] = useState<number | null>(null);
  const [personalUserId, setPersonalUserId] = useState<string | null>(null);
  const [userAccounts, setUserAccounts] = useState<UserAccount[]>([]);
  const [userAccountsLoading, setUserAccountsLoading] = useState(false);
  const [isEasyConnect, setIsEasyConnect] = useState(false);
  const [applicationsRoles, setApplicationsRoles] = useState<RessourceAccess | null>(null);
  const [userAccountsError, setUserAccountsError] = useState<boolean>(false);
  const [impersonateisLoading, setImpersonateIsLoading] = useState(false);

  const user = useMemo(() => getUser(), []);
  const token = user?.access_token;
  const auth = useAuth();

  const fetchUserAccounts = async () => {
    setUserAccountsLoading(true);
    try {
      const response: AxiosResponse = await axios.get(`${process.env.REACT_APP_USER_ACCOUNTS}`, {
        headers: { Authorization: `Bearer ${token}` }
      });


      if (response.data) {
        const { data } = response.data;

        const sortedAccounts: UserAccount[] = data.userAccounts.slice().sort((a: UserAccount, b: UserAccount) => {
          if (a.isMainAccount) return -1; // a comes first if it's the main account
          if (b.isMainAccount) return 1; // b comes first if it's the main account
          return 0; // maintain the original order for other items
        });
        setUserAccounts(sortedAccounts);
        setIsEasyConnect(data.easyConnect); // set Easyconnect
      } else {
        throw new Error('Response data is undefined');
      }
      setUserAccountsError(false);
    } catch (error: any) {
        const originalRequest = error.config;
        const message = error.response?.data || ''
        const currentLocation = window.location;
        if (error.response?.status === 401 && message.toLowerCase().includes('token') && !originalRequest._retry) {
            auth.signinRedirect({ redirectMethod: 'assign', redirect_uri: (process.env.REACT_APP_OIDC_REDIRECT + (currentLocation.pathname === '/' ? '' : currentLocation.pathname)).replace(/([^:]\/)\/+/g, "$1"), redirectTarget: 'self' })
        }
      setUserAccountsError(true);
    } finally {
      setUserAccountsLoading(false);
    }
  };

  const handleLogoutSilent = async () => {
    try {
      await auth.signoutSilent();
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleLoginSilent = async () => {
    try {
      setImpersonateIsLoading(true);
      await auth.signinSilent();
      setImpersonateIsLoading(false);
    } catch (error) {
      setImpersonateIsLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signoutRedirect();
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  useEffect(() => {
    if (!user) return;

    const userToken = user.access_token;
    const decodedToken: JwtPayload = jwtDecode(userToken);

    const accountId = (user?.profile.account as { id: number })?.id;
    setAccountId(accountId);

    setPersonalUserId(user?.profile.sub);

    fetchUserAccounts();
    setAccessToken(userToken);
    setApplicationsRoles(decodedToken.resource_access);
    setActiveToken(decodedToken);

  }, [user]);

  return (
    <KeycloakContext.Provider value={{
      activeToken,
      accessToken,
      accountId,
      personalUserId,
      userAccounts,
      applicationsRoles,
      isEasyConnect,
      userAccountsLoading,
      userAccountsError,
      impersonateisLoading,
      setAccountId,
      handleLogout,
      handleLogoutSilent,
      handleLoginSilent
    }}>
      {children}
    </KeycloakContext.Provider>
  );
}

export function useKeycloak() {
  const context = useContext(KeycloakContext);
  if (!context) {
    throw new Error('useKeycloak must be used within a KeycloakProvider');
  }
  return context;
}
