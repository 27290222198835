import { useState, useEffect } from 'react';
import { getUser } from '../context/KeycloakContext';

const useLocalStorage = <T = any>(key: string = '', savingData: T | null = null) => {
    const user = getUser()
    const user_id = user?.profile.preferred_username || user?.profile.email
    const readStorage = () => {
        const storage = localStorage.getItem(`${key}-${user_id}`)
        return storage ? JSON.parse(storage) : null
    }
    const [data, setData] = useState<T | null>(() => readStorage());

    useEffect(() => {
        if (savingData) {
            localStorage.setItem(`${key}-${user_id}`, JSON.stringify(savingData));
            window.dispatchEvent(new Event(`storage-${key}-${user_id}`));
        }
    }, [savingData]);

    useEffect(() => {
        function loadStoredData() {
            const storedData = readStorage()
            if (storedData) {
                setData(storedData)
            }
        }
        window.addEventListener(`storage-${key}-${user_id}`, loadStoredData)

        return () => {
            window.removeEventListener(`storage-${key}-${user_id}`, loadStoredData)
        }
    }, [])

    return {
        data,
        readStorage
    };
}

export default useLocalStorage;
