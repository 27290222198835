import { WebStorageStateStore } from "oidc-client-ts";
import { User } from "oidc-client-ts";
const currentLocation = window.location;

export const oidcConfig = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  redirect_uri: (process.env.REACT_APP_OIDC_REDIRECT + (currentLocation.pathname === '/' ? '' : currentLocation.pathname)).replace(/([^:]\/)\/+/g, "$1"),
  response_type: 'code',
  scope: 'openid',
  post_logout_redirect_uri: process.env.REACT_APP_OIDC_LOGOUT_REDIRECT,
  silent_redirect_uri: process.env.REACT_APP_OIDC_LOGOUT_REDIRECT,
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
};
