import { TFunction } from 'i18next';
import GenericMessage from '../components/GenericMessage/GenericMessage';

export const baseFontSize = 14; // Reflect the based font size in the css and used to calculate the skeleton heights

export const convertToTimeStamp = (dateObj: any) => { // Supports 'Day Month DD YYYY 00:00:00 GMT+0200 (Central European Summer Time)' format
    return new Date(dateObj).getTime();
}

export const formatToBicsGenericDate = (dateString: string | null, type?: 'short' | 'long' | 'calendar' | 'short-calendar'): string => {
    if (dateString === '' || !dateString) return '';

    const thisDate = dateString && dateString.endsWith('Z') ? new Date(dateString) : new Date(dateString + 'Z');
    const isShortType = type === 'short' || type === 'short-calendar';
    const isCalendarType = type === 'calendar' || type === 'short-calendar';

    const dateOptions: Intl.DateTimeFormatOptions = {
      weekday: isCalendarType ? 'short' : undefined,
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: isShortType ? undefined : 'numeric',
      minute: isShortType ? undefined : '2-digit',
      hour12: true,
      timeZoneName: isShortType ? undefined : 'short',
  };

    const formattedDate = thisDate.toLocaleDateString('en-US', dateOptions);
    
    return `${formattedDate}`;
};


export const formatDate = (timestamp: number) => { // Accepts only timestamp
    if (!timestamp) return '';
    const date = new Date(Number(timestamp) * 1000).toISOString();
    return formatToBicsGenericDate(date, 'short');
};

/* TOAST */
export const toast = (content: any, trigger: HTMLElement | null) => {
    if (!document.querySelector('.js-toast-wrap')) {
      const wrap = document.createElement('div');
      wrap.className = [`bc-toast-wrap js-toast-wrap`].join(' ');
      document.body.append(wrap);
    }
    function toastClick() {
      const wrap = document.querySelector('.js-toast-wrap');
      const tst = document.createElement('div');
      tst.className = [`bc-toast bc-toast--${content.status || 'information'}`].join(' ');
      if (!content.hideClose) {
        const close = document.createElement('button');
        close.className = [`bc-btn--close`].join(' ');
        close.innerHTML = `<span class="bc-icon-cross"></span>`;
        close.addEventListener('click', () => {
          tst.classList.remove('active');
          setTimeout(() => {
            tst.remove();
          }, 300);
        });
        tst.append(close);
      }
      const cod = `      
        <span class="bc-icon-${content.status || 'information'}${
        content.status !== 'congratulations' ? `-fill` : ''
      } bc-toast__icon"></span>
        <div class="bc-toast__content">
          <p class="bc-toast__title">${content.title}</p>
          <p class="bc-mb-0">${content.text}</p>
        </div>
      `;
      tst.insertAdjacentHTML('beforeend', cod);
      wrap && wrap.append(tst);
      setTimeout(() => {
        tst.classList.add('active');
      }, 10);
      if (content.timeout) {
        setTimeout(() => {
          tst.classList.remove('active');
          // 300ms is the default transition duration we use in CSS
        }, content.timeout - 300);
        setTimeout(() => {
          tst.remove();
        }, content.timeout);
      }
    }
    if (trigger) {
      trigger.addEventListener('click', toastClick);
    } else {
      toastClick();
    }
  };
  /* // TOAST */

  // Close dropdown when clicking outside
  export const handleClickOutside = (dropdownRef: React.RefObject<HTMLElement>, setIsOpen: React.Dispatch<React.SetStateAction<boolean>>) => (event: MouseEvent) => {
    if (dropdownRef.current && !(dropdownRef.current as HTMLElement).contains(event.target as Node)) {
      setIsOpen(false);
    }
  };
  
  // User management: Check if specific role is in the token
  export const hasUserRole = (rolesArray: string[], role: string) => {
    return rolesArray.some(val => val === role);
}

// Helper function to handle sorting tables

export const sortData = <T,>(data: T[], sortCriteria: { column: string, order: 'asc' | 'desc' }): T[] => {
  const sortedData = [...data].sort((a, b) => {
      const key = sortCriteria.column as keyof T;
      const aValue = a[key];
      const bValue = b[key];

      // Check if the values are dates
      if (aValue instanceof Date && bValue instanceof Date) {
          return sortCriteria.order === 'asc' ? 
            convertToTimeStamp(aValue) - convertToTimeStamp(bValue) : convertToTimeStamp(bValue) - convertToTimeStamp(aValue);
      }

      // If not dates, proceed with string comparison
      if (aValue && bValue) {
          const aValueString = Array.isArray(aValue) ? aValue.map(item => item.name).join(', ') : String(aValue);
          const bValueString = Array.isArray(bValue) ? bValue.map(item => item.name).join(', ') : String(bValue);

          return sortCriteria.order === 'asc' ? aValueString.localeCompare(bValueString) : bValueString.localeCompare(aValueString);
      }

      if (aValue < bValue) {
          return sortCriteria.order === 'asc' ? -1 : 1;
      } else if (aValue > bValue) {
          return sortCriteria.order === 'asc' ? 1 : -1;
      } else {
          return 0;
      }
  });

  return sortedData;
};

// Handle Errors in widgets
export const handleWidgetErrorMessage = (accessForbidden: boolean, t: TFunction, isPage?: boolean) => {
  const genericErrorMessage = isPage ? t('error.error_msg') : t('dashboard.widgets.error');

  return (
    <GenericMessage
      type={accessForbidden ? 'info' : 'error'}
      title={accessForbidden ? undefined : t('error.error_title')}
      msg={accessForbidden ? t('dashboard.widgets.access_forbidden') : genericErrorMessage}
    />
  );
};

export const replaceHyphens = (str: string) => str.replace(/-/g, ' ');
